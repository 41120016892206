<template>
	<div class="grid lg:grid-cols-3 gap-5">
		<div class="lg:col-span-2 h-full w-full overflow-y-auto">
			<n-data-table title="銷售訂單列表" :columns="fields" :data="saleOrders" actions="view,edit,search,refresh" @view="getSaleOrder" @edit="archiveSaleOrder">
				<template v-slot:merchant="{ row }"> {{ row.merchant.name['tc'] }}</template>
				<template v-slot:location="{ row }"> {{ row.location.name['tc'] }}</template>
				<template v-slot:customer="{ row }"> {{ row.customer.firstName + ' ' + row.customer.lastName }}</template>
				<template v-slot:status="{ row }">
					<n-badge :color="adjustmentStatusName(row.status).color"> {{ adjustmentStatusName(row.status).label }}</n-badge>
				</template>
			</n-data-table>
		</div>
		<div class="lg:col-span-1 space-y-2 text-xl h-full overflow-auto">
			<p class="text-4xl pb-2">{{ saleOrder.id ? '修改' : '新增' }}銷售訂單</p>
			<div class="flex space-x-2">
				<label for="merchant">所屬商戶</label>
				<n-select v-model="saleOrder.merchant" :options="merchants" @input="saleOrder.merchantId = saleOrder.merchant.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇商戶"></n-select>
			</div>

			<div v-if="saleOrder.merchant.id" class="flex space-x-2">
				<label for="name">位置</label>
				<n-select v-model="saleOrder.location" :options="locationByMerchantId(saleOrder.merchant.id)" @input="saleOrder.locationId = saleOrder.location" :reduce="option => option.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇位置"></n-select>
			</div>
			<div v-if="saleOrder.merchant.id" class="flex space-x-2">
				<label for="name">會員</label>
				<n-select v-model="saleOrder.customer" :options="customerByMerchantId(saleOrder.merchant.id)" @input="saleOrder.customerId = saleOrder.customer" :reduce="option => option.id" :get-option-label="option => option.firstName + ' ' + option.lastName" class="w-full h-full text-base" placeholder="請選擇會員"></n-select>
			</div>

			<div v-if="saleOrder.merchant.id" class="flex space-x-2">
				<label for="status">訂單類別</label>
				<n-select v-model="saleOrder.method" :options="saleOrderTypeOptions" :reduce="status => status.value" class="w-full h-full text-base" placeholder="請選擇訂單類別"></n-select>
			</div>

			<div v-if="saleOrder.items.length > 0" class="space-y-2">
				<p class="text-xl">商品銷售訂單列表</p>
				<div v-for="(item, index) in saleOrder.items" :key="(item, index)">
					<div class="flex space-x-2">
						<label for="product">商品</label>
						<n-select v-model="item.product" :options="catalogByMerchantId(saleOrder.merchant.id, 'PRODUCT')" @input="item.productId = item.product.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇商品"></n-select>
					</div>
					<div class="flex space-x-2">
						<label for="variant">變化</label>
						<n-select v-model="item.variant" :options="catalogByMerchantId(saleOrder.merchant.id, 'VARIANT')" @input="item.variantId = item.variant.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
					</div>
					<div class="flex space-x-2">
						<label for="number">數量</label>
						<input type="number" v-model.number="item.quantity" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇數量" />
					</div>
					<div v-if="item.price" class="flex space-x-2">
						<label for="number">{{ saleOrder.method }}價錢</label>
						<input type="number" disabled v-model.number="item.price[saleOrder.method]" class="w-full h-full text-base border border-gray px-3" />
					</div>
				</div>
			</div>

			<div v-if="saleOrder.merchant.id" class="space-y-2">
				<p class="text-xl">加入新商品</p>
				<div class="flex space-x-2">
					<label for="product">商品</label>
					<n-select
						v-model="saleItem.product"
						:options="catalogByMerchantId(saleOrder.merchant.id, 'PRODUCT')"
						@input="
							saleItem.productId = saleItem.product.id
							saleItem.variant = ''
						"
						:get-option-label="option => option.name['tc']"
						class="w-full h-full text-base"
						placeholder="請選擇商品"
					></n-select>
				</div>
				<div class="flex space-x-2">
					<label for="variant">變化</label>
					<n-select v-model="saleItem.variant" :options="catalogByMerchantId(saleOrder.merchant.id, 'VARIANT')" @input="saleItem.variantId = saleItem.variant.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
				</div>

				<div class="flex space-x-2">
					<label for="number">數量</label>
					<input type="number" v-model.number="saleItem.quantity" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇類別" />
				</div>

				<div v-if="saleItem.price" class="flex space-x-2">
					<label for="number">價錢</label>
					<input type="number" v-model.number="saleItem.price" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇價錢" />
				</div>

				<n-button @onClick="addSaleItem(saleItem)">加入新商品</n-button>

				<div class="flex space-x-2">
					<label for="status">銷售訂單狀態</label>
					<n-select v-model="saleOrder.status" :options="adjustmentStatusOptions" :reduce="status => status.value" class="w-full h-full text-base" placeholder="請選擇狀態"></n-select>
				</div>

				<p v-if="error" class="text-danger">{{ '錯誤訊息:' + error }}</p>

				<div class="flex space-x-2">
					<n-button v-if="saleOrder.id" @onClick="$router.go(0)" color="danger">返回</n-button>
					<n-button @onClick="saleOrder.id ? updateSaleOrder(saleOrder) : createSaleOrder(saleOrder)">{{ saleOrder.id ? '修改銷售訂單' : '加入銷售訂單' }}</n-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { saleOrder, currentSaleItem } from '@/model/saleOrder'

export default {
	name: 'Home',
	data() {
		return {
			saleItem: currentSaleItem(),
			saleOrder: saleOrder(),
			fields: [
				{
					label: '銷售編號',
					prop: 'referenceId',
					sortable: true,
				},
				{
					label: '所屬商戶',
					prop: 'merchant',
					sortable: true,
				},
				{
					label: '入庫位置',
					prop: 'location',
					sortable: true,
				},
				{
					label: '會員',
					prop: 'customer',
					sortable: true,
				},
				{
					label: '訂單類別',
					prop: 'method',
					sortable: true,
				},
				{
					label: '商品小計',
					prop: 'productTotal',
					sortable: true,
				},
				{
					label: '總計',
					prop: 'grandTotal',
					sortable: true,
				},
				{
					label: '庫存狀態',
					prop: 'status',
					sortable: true,
				},
			],
			error: '',
		}
	},
	computed: {
		...mapState(['statusOptions', 'adjustmentStatusOptions', 'saleOrderTypeOptions']),
		...mapGetters([
			// merchant
			'merchants',
			'merchantName',
			'currentMerchant',
			// customer
			'customers',
			'customerByMerchantId',
			'customerName',
			// location
			'locations',
			'locationByMerchantId',
			'locationName',
			// catalog
			'catalog',
			'catalogMerchant',
			'catalogByMerchantId',
			'catalogItem',
			// inventory
			'inventories',
			'currentInventory',
			// saleOrder
			'saleOrders',
			'currentSaleOrder',
			// state
			'statusName',
			'adjustmentStatusName',
		]),
	},
	methods: {
		async addSaleItem(saleItem) {
			if (!saleItem.product) return
			if (!saleItem.variant) return
			await this.saleOrder.items.push(saleItem)
			this.saleItem = await currentSaleItem()
		},

		async createSaleOrder(data) {
			try {
				data.merchantId = data.merchant.id
				data.locationId = data.location
				data.customerId = data.customer
				data.items.forEach(item => ((item.productId = item.product.id), (item.variantId = item.variant.id)))
				await this.$store.dispatch('createSaleOrder', { saleOrder: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async getSaleOrder(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('getSaleOrder', { id })
				this.saleOrder = this.currentSaleOrder
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async archiveSaleOrder(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('archiveSaleOrder', { id })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async updateSaleOrder(data) {
			try {
				data.merchantId = data.merchant.id
				data.locationId = data.location.id
				data.customerId = data.customer.id
				data.items.forEach(item => ((item.productId = item.product.id), (item.variantId = item.variant.id)))
				this.error = ''
				await this.$store.dispatch('updateSaleOrder', { id: data.id, saleOrder: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
	},
}
</script>
