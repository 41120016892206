export function saleOrder() {
	return {
		merchant: [],
		location: [],
		customer: [],
		method: '',
		items: [],
		status: '',
	}
}

export function currentSaleItem() {
	return {
		product: '',
		variant: '',
		quantity: 0,
	}
}
